import React, { useEffect } from 'react';

const NotFound = () => {
  useEffect(() => {
    document.title = '404 - Page Not Found';
    if (window.location.pathname !== '/404.html') {
      window.history.replaceState(null, '', '/404.html');
    }
    fetch('/api/set-404', { method: 'POST' });
  }, []);

  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
