import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { breadcrumbs, profile } from './structured';

export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <>
        <Helmet>
          <script type="application/ld+json">{breadcrumbs}</script>
          <script type="application/ld+json">{profile}</script>
        </Helmet>
        <section id="about">
          <div className="row">
            <div className="three columns">
              <img className="profile-pic" src="images/profilepic.jpg" alt="It's me!" />
            </div>

            <div className="nine columns main-col">
              <h2>About Me</h2>
              <p>{resumeData.aboutme}</p>

              <div className="row">
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>{resumeData.name}</span>
                    <br></br>
                    <span>{resumeData.address}</span>
                    <br></br>
                    <span>
                      <a href={resumeData.website}>{resumeData.website}</a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
