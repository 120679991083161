let breadcrumbs = JSON.stringify([
  {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://www.costin.rocks',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: "Gunks' Hardest Routes",
        item: 'https://www.costin.rocks/gunksHardest',
      },
    ],
  },
  {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://www.costin.rocks',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Blog',
        item: 'https://www.costin.rocks/blog',
      },
    ],
  },
  {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://www.costin.rocks',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'API',
        item: 'https://www.costin.rocks/api',
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: 'Hardest Routes',
        item: 'https://www.costin.rocks/api/gethardestroutes',
      },
    ],
  },
]);

let profile = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'ProfilePage',
  mainEntity: {
    '@type': 'Person',
    name: 'Costin Anghel',
    givenName: 'Costin',
    familyName: 'Anghel',
    email: 'costin.anghel@costin.rocks',
    telephone: '9548259303',
    jobTitle: 'Platform Architect',
    image: 'https://www.costin.rocks/images/profilepic.jpg',
    url: 'https://www.costin.rocks/',
    worksFor: {
      '@type': 'Organization',
      name: 'Boomi, LP',
      url: 'https://boomi.com/',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Chesterbrook',
        addressRegion: 'PA',
        postalCode: '19087',
        streetAddress: '1400 Liberty Ridge Dr',
        addressCountry: 'USA',
      },
    },
    gender: 'male',
    alumniOf: [
      {
        '@type': 'CollegeOrUniversity',
        name: 'University of Florida',
      },
    ],
    sameAs: [
      'https://github.com/coanghel',
      'https://www.linkedin.com/in/costin-anghel-365b2360/',
      'https://www.facebook.com/costin.anghel/',
    ],
  },
});

let dataset = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'Dataset',
  name: 'Gunks Hardest Routes',
  alternateName: ["Gunk's Hardest Routes", 'Gunks Hardest Climbs', "Gunk's Hardest Climbs", 'gunks-hardest'],
  description:
    'Gunks Hardest Routes contains a currated, organized, and formatted list of the most challenging climbs in the Shawangunk Ridge maintained by the local Gunks community',
  keywords: [
    'SPORTING ACTIVITY  > ROCK CLIMBING > TRADITIONAL',
    'SPORTING ACTIVITY  > ROCK CLIMBING > SPORT',
    'SPORTING ACTIVITY  > ROCK CLIMBING > TOP ROPE',
    'SPORTING ACTIVITY  > ROCK CLIMBING > MIXED',
  ],
  creator: [
    {
      '@type': 'Person',
      givenName: 'Andy',
      familyName: 'Salo',
      name: 'Andy Salo',
    },
    {
      '@type': 'Person',
      givenName: 'Alan',
      familyName: 'Kline',
      name: 'Alan Kline',
    },
    {
      '@type': 'Person',
      givenName: 'Matt',
      familyName: 'Santisi',
      name: 'Matt Santisi',
    },
    {
      '@type': 'Person',
      givenName: 'Costin',
      familyName: 'Anghel',
      name: 'Costin Anghel',
    },
  ],
  license: 'https://creativecommons.org/publicdomain/zero/1.0/',
  isAccessibleForFree: true,
  distribution: [
    {
      '@type': 'DataDownload',
      encodingFormat: 'JSON',
      contentUrl: 'https://www.costin.rocks/api/getHardestRoutes',
    },
  ],
  spatialCoverage: {
    '@type': 'Place',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: '41.703889',
      longitude: '-74.344722',
    },
    name: 'Shawangunk Ridge',
  },
});

let faq = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    {
      '@type': 'Question',
      name: 'What are the hardest climbing routes in the Gunks?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'We maintain a living document by soliciting input from the local Gunks community. Search our list by route name, first ascent, or grade.',
      },
    },
    {
      '@type': 'Question',
      name: 'Is this data publically available?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes! You can access our API within reason. Please contact Costin Anghel for details.',
      },
    },
  ],
});

export { breadcrumbs, profile, dataset, faq };
